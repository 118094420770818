<template>
  <div id="experience" class="pt-20">
    <!-- Header -->
    <h1 class="
              border-b border-green-800
              text-green-500
              font-mono
              text-base
              md:text-xl
              lg:mx-0
            ">
      Experience
    </h1>
    <div class="mt-10 grid gap-6 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
      <div class="text-gray-50" v-for="project in projects" :key="project">
        <Project :project="project" />
      </div>
    </div>
  </div>
</template>

<script>
import Project from "./Project.vue";

export default {
  name: "Experience",
  data() {
    return {
      projects: [
        {
          imgURL: "images/prisms.jpg",
          company: "Prisms of Reality",
          header: "Senior VR Engineer",
          description:
            "Designed and engineered 3D interactive modules that teach mathematical concepts to students.",
          points: ["Quest 2"],
          years: "2022 - CURRENT",
          link: "https://vimeo.com/865784429",
        },
        {
          imgURL: "images/ready.jpg",
          company: "MOTH+FLAME",
          header: "Senior VR Developer",
          description:
            "Constructed core architecture for suicide prevention training utilizing speech recognition and emergency aircraft procedures.",
          points: ["Quest 2"],
          years: "2020 - 2022",
          link: "https://vimeo.com/549063799",
        },
        {
          imgURL: "images/magic-leap-world.jpg",
          company: "MAGIC LEAP",
          header: "Unity Developer",
          description:
            "Re-engineered the mixed reality app store to amplify spatial design.",
          points: ["Magic Leap 1"],
          years: "2017 - 2020",
        },
        {
          imgURL: "images/dialect-effect.jpg",
          company: "SCAD",
          header: "Student Programmer",
          description:
            "Created a Language learning VR experience utilizing speech recognition.",
          points: ["HTC Vive"],
          years: "2017 (3 Months)",
          link: "https://drive.google.com/file/d/0B0lskjfVASz-OF84cG5tSlZRV1E/view?usp=share_link&resourcekey=0-iuk84UyZmvNxK-w_nwZBTw",
        },
      ],
    };
  },
  methods: {},
  components: {
    Project,
  },
};
</script>
              
