<template>
  <div id="projects" class="pt-20">
    <!-- Header -->
    <h1
      class="
        border-b border-green-800
        text-green-500
        font-mono
        text-base
        md:text-xl
        lg:mx-0
      "
    >
      Projects
    </h1>

    <div class="mt-10 grid gap-6 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
      <div class="text-gray-50" v-for="project in projects" :key="project">
        <Project :project="project" />
      </div>
    </div>
  </div>
</template>

<script>
import Project from "./Project.vue";

export default {
  name: "Projects",
  data() {
    return {
      projects: [
        {
          imgURL: "images/team-seas-turbo.jpg",
          company: "Downloadable Game",
          header: "#TeamSeas Turbo",
          description:
            "Help save the ocean by collecting trash with MrBeast and Mark Rober!",
          points: ["Unity"],
          years: "2021",
          link: "https://commandv.itch.io/team-seas-turbo",
        },
        {
          imgURL: "images/conceptinator.jpg",
          company: "Website",
          header: "Conceptinator",
          description: "A web app that helps generate ideas for artists",
          points: ["Vue, Tailwind CSS"],
          years: "2021",
          link: "https://www.conceptinator.com/",
        },
        {
          imgURL: "images/roadkill-redux.jpg",
          company: "Web Game",
          header: "Roadkill Redux",
          description: "Dodge cars in this survival arcade game. ",
          points: ["Unity"],
          years: "2020",
          link: "https://vitasa.itch.io/roadkill-redux",
        },
        {
          imgURL: "images/morph.jpg",
          company: "Downloadable Game",
          header: "MORPH",
          description:
            "Float, crash, and splash your way through in this adorable action platformer.",
          points: ["Unity"],
          years: "2016",
          link: "https://vitasa.itch.io/morph",
        },
        {
          imgURL: "images/monochrome.jpg",
          company: "Downloadable Game",
          header: "MONOCHROME",
          description:
            "Navigate through the darkness in this top-down puzzle game.",
          points: ["Unity"],
          years: "2015",
          link: "https://vitasa.itch.io/monochrome",
        },
      ],
    };
  },
  methods: {},
  components: {
    Project,
  },
};
</script>
              