<template>
  <div
    class="relative h-full max-w-xl bg-gray-900 bg-opacity-50 md:bg-opacity-20 rounded-lg"
  >
    <div
      v-if="project.link"
      class="overflow-hidden rounded-tl-lg rounded-tr-lg"
    >
      <a :href="project.link" target="_blank">
        <img
          class="transition duration-150 ease-out transform-gpu hover:scale-110"
          :src="project.imgURL"
        />
      </a>
    </div>
    <div v-else>
      <img class="rounded-tl-lg rounded-tr-lg" :src="project.imgURL" />
    </div>

    <div class="px-4 py-6">
      <div class="flex justify-between mb-2">
        <div
          class="tracking-widest text-xs title-font font-medium text-green-500"
        >
          {{ project.company }}
        </div>
        <div
          class="tracking-widest text-xs title-font font-medium text-gray-500"
        >
          {{ project.years }}
        </div>
      </div>
      <div class="flex flex-col space-y-2 pb-4">
        <div class="text-xl font-semibold">
          {{ project.header }}
        </div>

        <div class="text-gray-300 text-sm leading-relaxed">
          {{ project.description }}
        </div>

        <ul
          class="absolute bottom-0 right-0 text-gray-300 font-mono text-xs px-2 py-1 bg-gray-900 rounded"
        >
          <li v-for="point in project.points" :key="point">{{ point }}</li>
        </ul>

        <a
          v-if="project.link"
          :href="project.link"
          target="_blank"
          class="flex flex-row items-center hover:text-green-500"
        >
          <div class="text-gray text-sm leading-relaxed">Learn More</div>
          <svg
            class="w-4 h-4 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: ["project"],
  data: () => {
    return {};
  },
};
</script>
