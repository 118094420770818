<template>
  <header>
    <nav
      id="nav"
      class="
        px-4
        pt-5
        h-15
        container
        mx-auto
        justify-center
        flex flex-col
        max-w-6xl
        fixed
        inset-x-0
        bg-black
      "
    >
      <div class="flex justify-between items-center text-gray-50">
        <!-- Logo -->
        <div class="flex items-center mr-4">
          <a href="#home">
            <img
              src="images/carl-vitasa-logo.svg"
              width="60"
              height="60"
              alt="logo"
              class="nav-elements"
            />
          </a>
        </div>
        <!-- nav elements -->
        <div class="flex space-x-5 space-y-0 py-4 flex-row font-semibold">
          <a href="#experience" class="relative highlight-line w-max">
            <span> Experience </span>
            <span
              class="
                absolute
                -bottom-1
                left-0
                w-0
                transition-all
                h-1
                bg-green-500
              "
            ></span>
          </a>
          <a href="#projects" class="relative highlight-line w-max">
            <span> Projects </span>
            <span
              class="
                absolute
                -bottom-1
                left-0
                w-0
                transition-all
                h-1
                bg-green-500
              "
            ></span>
          </a>
          <a
            href="/Carl Vitasa - Résumé.pdf"
            target="_blank"
            class="relative highlight-line w-max"
          >
            <span> Resume </span>
            <span
              class="
                absolute
                -bottom-1
                left-0
                w-0
                transition-all
                h-1
                bg-green-500
              "
            ></span>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style scoped>
.highlight-line:hover span:last-child {
  width: 100%;
}
</style>
