<template>
  <section class="text-gray-50 body-font">
    <div class="container mx-auto flex py-18 md:flex-row flex-col items-center">
      <div class="">
        <!-- <img
          class="object-cover object-center rounded-lg"
          alt="hero"
          src="images/headset.jpg"
        /> -->
        <div class="flex justify-center px-4">
          <model-viewer
            class="w-80 h-80"
            loading="lazy"
            src="vr_headset.glb"
            poster="images/headset-poster.jpg"
            camera-orbit="45deg 90deg 45deg"
            shadow-intensity="1"
            alt="VR Headset"
            auto-rotate
            auto-rotate-delay="0"
            camera-controls
            interaction-prompt="none"
            rotation-per-second="30deg"
            disable-zoom
          >
          </model-viewer>
        </div>
      </div>
      <div
        class="px-4 lg:flex-grow flex flex-col md:items-start md:text-left items-center text-center"
      >
        <p class="mb-1 hero-intro text-green-500 font-mono text-base">
          // Hi there, my name is
        </p>
        <h1 class="font-sans font-bold mb-4">
          <span class="block text-5xl text-white"> Carl Vitasa </span>
          <span class="block lg:text-5xl md:text-4xl text-2xl text-gray-200">
            I build VR experiences
          </span>
        </h1>
        <p class="mt-1 name-and-occupation block">
          I love collaborating with artists and designers on projects that
          utilize cutting edge 3D technologies (AR/VR/MR).
        </p>
      </div>
    </div>
  </section>
</template>

