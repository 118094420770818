<template>
  <div id="home" class="h-screen">
    <div class="container mx-auto justify-center flex flex-col max-w-6xl">
      <NavBar />
      <main class="pt-20 px-4 flex-grow">
        <Hero />
        <Experience />
        <Projects />
        <!-- <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
          >
            <component :is="Component" />
          </transition>
        </router-view> -->
      </main>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Hero from "./components/Hero.vue";
import Experience from "./components/Experience.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "app",
  data: () => {
    return {};
  },
  methods: {},
  components: {
    NavBar,
    Hero,
    Experience,
    Projects,
    Footer,
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
  background-color: black;
}
#nav {
  z-index: 1;
}
model-viewer {
  --poster-color: transparent;
}
</style>
